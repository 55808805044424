@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light);
/* CELL */

/* STAGE */

/* DISPLAY */

/* navbar styles */
nav
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 130px;
    background: rgb(10, 12, 13);
    box-shadow: 0px 4px 30px 0px #0000000d;
    padding-left: 10%;
    padding-right: 10%;
}
.logo-section-container
{
    display: flex;
    max-height: 100%;
}
.logo-container
{
    display: flex;
    align-items: center;
}
.logo-text
{
    color: rgb(247, 243, 238);
    font-size: 2.8rem;
    font-weight: 700;
    padding-left: 20px;
}
.nav-a-tag-container a
{
    text-decoration: none;
    margin-right: 20px;
}
.navbar-rightside-content-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follow-us-div-container
{
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 10px 15px;
    background: #0b02231a;
}
.follow-us-icons
{
    padding-left: 13px;
}

/* banner section style */
.banner-section
{
    width: 95%;
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
}
.banner-content-container
{
    width: 50%;
}
.left-banner-bg
{
    background-image: url(/static/media/Group14.62b2dbfd.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.banner-left-side
{
    max-width: 400px;
    margin: 0 auto;
    margin-top: 70px;
    text-align: left;
}
.banner-left-side li
{
    list-style: none;
    text-align: left;
    margin-left: 0;
    margin-bottom: 20px;
}
.banner-content-heading
{
    color: rgb(247, 243, 238);
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;

}

.text-goat
{
    font-size: 3rem;
    font-weight: 800;
    margin-top: 0;
    padding-top: 0;
}
#btn-pink
{
    background: #f74edb;
}
.banner-right-side
{
    display: flex;
    justify-items: right;
    width: 45%;
}
.banner-right-side > img
{
    width: 90%;
    object-fit: cover;
}

/* Footer Style section */
footer
{
    background: rgb(10, 12, 13);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.footer-content-container
{
    width: 700px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
}
.footer-logo-container
{
    display: flex;
    justify-content: center;
}

.footer-logo-text
{
    color: #FFF;
    font-size: 45px;
    font-weight: 700;
    margin-left: 10px;
}
.footer-p-tag-container
{
    width: 100%;
    display: inline-flex;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
}
.footer-services-text
{
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    font-weight: 600px;
}
.footer-icon-container
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-icon-container i
{
    color: #FFF;
    padding-left: 80px;
}
.terms-condition
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}
.footer-text
{
    color: #ffffff99;
    font-size: 12px;
    font-weight: 500;
}
.last-part
{
    margin-bottom: 20px;
}

/* START-BUTTON */

/* main section starts here */

/* Instant Game section */
.play-instant-game-section
{
    display: flex;
    margin-top: 100px;

}
.play-instant-text
{
    width: 200px;
    margin-left: 200px;
    margin-top: 200px;
    margin-right: 200px;
}
.play-instant-text h3
{
    font-size: 28px;
    font-weight: 700;
}
.play-instant-p
{
color: #534C64;
font-size: 12px;
font-weight: 400;
}
.play-instant-image-container
{
    width: 50%;
}
.cow-boy-image {
    width: 70%;
    margin-left: 120px;
}
.small-image
{
    width: 40%;
    margin-top: -100px;
}
/* online games funny video section */
.online-game-text
{
    color: #fff;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
}
.grid-box-layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 15px;
    grid-gap: 10px;
    gap: 10px;
}

.grid-item {
    position: relative;
    cursor: pointer;
}
.grid-item h3 {
    position: absolute;
    bottom: 18%; /* Adjust the distance from the bottom as needed */
    left: 50%;
    transform: translateX(-50%) translateY(-120%);
    font-size: 20px;
    font-weight: bold;
    background: linear-gradient(90deg, #FF42A5 0%, #FF42A5 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.grid-item:hover .overlay {
    opacity: 1;
}
.grid-item:hover div
{
    border-radius: 15px;
    border: 2px solid #FF42A5;
}

.grid-item i {
    font-size: 48px;
    color: #fff;
}

.grid-item-text-description {
  color: #fff;
}

/* online game section */

.games-container
{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.games-content-container
{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200px;
    height: 70px;
    border-radius: 40px;
    margin: 10px 5px;
}
.games-content-container:hover
{
    width: 198px;
    height: 68px;
    background: var(--linear, linear-gradient(90deg, #FF42A5 0%, #FF42A5 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid #FF42A5;
}
.games-content-container p
{
    padding: 12px 18px;
    color: #0B0223;
    font-size: 15px;
    font-weight: 600;
}
.games-content-container img
{
    width: 60px;
    height: 60px;
    border-radius: 40px;
    margin-left: 8px;
}

/* navbar styles */
nav
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 130px;
    background: rgb(10, 12, 13);
    box-shadow: 0px 4px 30px 0px #0000000d;
    padding-left: 10%;
    padding-right: 10%;
}
.logo-section-container
{
    display: flex;
    max-height: 100%;
}
.logo-container
{
    display: flex;
    align-items: center;
}
.logo-text
{
    color: rgb(247, 243, 238);
    font-size: 2.8rem;
    font-weight: 700;
    padding-left: 20px;
}
.nav-a-tag-container a
{
    text-decoration: none;
    margin-right: 20px;
}
.navbar-rightside-content-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follow-us-div-container
{
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 10px 15px;
    background: #0b02231a;
}
.follow-us-icons
{
    padding-left: 13px;
}

/* GLOBAL */

/* shared styles section */

:root {
  /**
 * colors
 */

 --rich-black-fogra-29_95: hsla(222, 18%, 11%, 0.95);
 --raisin-black-1: hsl(0, 0%, 16%);
 --raisin-black-2: hsl(236, 17%, 17%);
 --raisin-black-3: hsl(280, 11%, 11%);
 --raisin-black-4: hsl(280, 8%, 15%);
 --english-violet: hsl(224, 58%, 24%);
 --eerie-black-1: hsl(277, 25%, 10%);
 --eerie-black-2: hsl(280, 7%, 8%);
 --roman-silver: hsl(220, 6%, 59%);
 --quick-silver: hsl(0, 1%, 65%);
 --light-gray-1: hsl(0, 0%, 80%);
 --light-gray-2: hsl(0, 2%, 82%);
 --marigold_75: hsla(42, 99%, 46%, 0.75);
 --xiketic_90: hsla(280, 37%, 8%, 0.9);
 --cultured-2: hsl(0, 0%, 97%);
 --marigold: hsl(42, 99%, 46%);
 --platinum: hsl(0, 0%, 89%);
 --dim-gray: hsl(0, 0%, 42%);
 --white_15: hsla(0, 0%, 100%, 0.15);
 --white_10: hsla(0, 0%, 100%, 0.1);
 --xiketic: hsl(277, 25%, 10%);
 --silver: hsl(0, 0%, 78%);
 --white: hsl(0, 0%, 100%);
 --jet: hsl(236, 13%, 23%);
}

body
{
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  padding: 0;
}
.section-max-width
{
  max-width: 80%;
  margin: 100px auto;
}
img
{
  width: 100%;
}

.goldish-color
{
  background: linear-gradient(90deg, var(--marigold), var(--marigold_75));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.description-text
{
  color: #FFF;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}
.gradient-text
{
  background: linear-gradient(180deg, #f948f0 0%, #eef158 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-banner
{
  display: inline-flex;
  padding: 15px 25px;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 40px;
  background: linear-gradient(90deg, var(--marigold), var(--marigold_75));
  color: #FFF;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  margin-right: 10px;
}

@font-face {
  font-family: 'Pixel';
  src: url(/static/media/Pixel-LCD-7.63d9c151.woff) format('woff');
}

/* Responsive layout for mobile devices  */
@media screen and (max-width: 576px)
{
   body
   {
       margin: 0;
       padding: 0;
   }
   .description-text
   {
       font-size: 0.8rem;
   }

   nav
   {
       margin: 20px auto;
       flex-direction: column;
       max-width: 60%;
       box-shadow: none;
       justify-items: center;
   }
   .logo-section-container
   {
       display: flex;
       align-items: center;
   }
   .logo-container
   {

    height: 40px;
    width: 40px;
   }
   .logo-text
   {
       color: rgb(247, 243, 238);
       font-size: 1.8rem;
       font-weight: 700;
       padding-left: 10px;
   }
   .navbar-rightside-content-container
   {
       align-items: center;
   }
   .nav-a-tag-container
   {
       display: flex;
       flex-direction: column;
       justify-items: center;
       margin-right: auto;
   }
   .nav-a-tag-container > a , div
   {
       margin-bottom: 20px;
   }
   .follow-us-div-container
   {
       display: inline-flex;
       margin: 0 auto;
       align-items: center;
       border-radius: 8px;
       padding: 5px 8px;
       background: #0b02231a;
   }



   /* Banner section responsive style */
   .banner-section
   {
        width: 85%;
        margin: 0 auto;
        margin-top: 200px;
        flex-direction: column-reverse;
   }
   .banner-content-container
   {
       width: 80%;
       padding-left: 50px;
       margin: 0 auto;
       background-image: url(/static/media/Group14.62b2dbfd.png);
       background-repeat: no-repeat;
       background-size: contain;
   }
   .left-banner-bg
    {
        background-image: none;
    }

   .banner-left-side
   {
       max-width: 400px;
       margin: 0 auto;
       margin-top: 70px;
       text-align: left;
   }

   .banner-content-heading
   {
       font-size: 1.5rem;

   }

   .text-goat
   {
       font-size: 2.2rem;
   }
   .btn-banner
    {
        padding: 8px 15px;
        grid-gap: 8px;
        gap: 8px;
        font-size: 0.7rem;
        margin-right: 8px;
    }

   .banner-right-side
   {
       display: flex;
       justify-items: right;
       width: 93%;
       margin: 0 auto;
       margin-bottom: 50px;
   }
   .banner-right-side > img
   {
       width: 100%;
       object-fit: cover;
   }


   /* online games funny video section */
    .online-game-funny-video-section
    {
        text-align: center;
    }
    .online-game-text
    {
        font-size: 2.2rem;
    }
    .grid-box-layout
    {
        grid-template-columns: repeat(1, 1fr);
    }


   /* Play Instant games section */
   .play-instant-game-section
    {
        display: flex;
        margin-top: 0px;
        flex-direction: column;

    }
    .play-instant-text
    {
        width: 200px;
        margin-left: 120px;
        margin-top: 100px;
        margin-right: 100px;
    }
    .play-instant-text h3
    {
        font-size: 22px;
    }
    .play-instant-image-container
    {
        width: 50%;
    }
    .cow-boy-image {
        width: 80%;
    }
    .small-image
    {
        width: 50%;
        margin-top: -85px;
        margin-left: 70px;
    }


       /* Online game responsive layout */

       .games-content-container
       {
           display: flex;
           align-items: center;
           justify-content: space-around;
           width: 100px;
           height: 50px;
           margin: 8px 5px;
       }
       .games-content-container:hover
       {
           width: 93px;
           height: 43px;
           padding: 5px 5px;
           border-radius: 40px;
       }
       .games-content-container p
       {
           padding: 8px 10px;
           color: rgb(247, 243, 238);
           font-size: 10px;
           font-weight: 600;
       }
       .games-content-container img
       {
           width: 40px;
           height: 35px;
           border-radius: 20px;
           margin-left: 8px;
       }


   /* footer styles */
   footer
   {
       width: calc(100% - 160px);
       padding-top: 50px;
       padding: 50px 80px;
   }
   .footer-content-container
   {
       width: 100%;
       margin: 0 auto;
       margin-bottom: 10px;
       text-align: center;
   }
   .footer-logo-container
   {
       display: flex;
       justify-content: center;
       width: calc(100% - 20%);
   }

   .footer-logo-container img
   {
        width: 50px;
   }
   .footer-logo-text
   {
       color: #FFF;
       font-size: 25px;
       font-weight: 700;
       margin-left: 10px;
   }
   .footer-p-tag-container
   {
       width: 90%;
       display: flex;
       padding: 10px;
       margin: 0 auto;
       align-items: center;
       justify-content: space-around;
       margin-bottom: 20px;
       flex-wrap: wrap;
       width: 150px;
   }

   .footer-services-text
   {
       color: #FFF;
       width: 40px;
       text-align: center;
       font-size: 10px;
       font-weight: 600px;
       margin-right: 5px;
   }
   .footer-icon-container
   {
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .footer-icon-container i
   {
        padding-left: 20px;
        color: #FFF;
        width: calc(100% - 20%);
   }
   .terms-condition
   {
       margin-top: 20px;
       width: 100%;
       flex-wrap: wrap;
   }
   .footer-text
   {
       color: #ffffff99;
       font-size: 10px;
       font-weight: 500;
   }
   .policy-text
   {
    width: 120px;
   }
   .last-part
   {
       margin-bottom: 20px;
   }

}


/* Responsive layout for Tablet devices  */
@media screen and (min-width: 577px) and (max-width: 1050px)
{
    /* Shared Common Styles */
    body
    {
        margin: 0;
        padding: 0;
    }

    /* header section styles */

    /* Nav bar tablet styles */

    .description-text
    {
       font-size: 1rem;
    }

    nav
        {
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 20px;
            flex-direction: column;
            width: 80%;
            justify-items: center;
        }
        .logo-section-container
        {
            display: flex;
            align-items: center;
        }
        .logo-container
        {

         height: 60px;
         width: 60px;
        }
        .logo-text
        {
            color: rgb(247, 243, 238);
            font-size: 2rem;
            font-weight: 700;
            padding-left: 10px;
        }
        .navbar-rightside-content-container
        {
            align-items: center;
            justify-items: center;
            align-items: center;
        }
        .nav-a-tag-container
        {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
        }

        .follow-us-div-container
        {
            display: inline-flex;
            margin: 0 auto;
            align-items: center;
            border-radius: 8px;
            padding: 5px 8px;
            background: #0b02231a;
        }


    /* banner tablet responsive design */


    .banner-section
   {
        width: 100%;
        margin: 0 auto;
        margin-top: 200px;
        flex-direction: column-reverse;
        justify-content: center;
   }

    .banner-content-container
   {
       width: 90%;
       padding-left: 50px;
       margin: 0 auto;
       background-image: url(/static/media/Group14.62b2dbfd.png);
       background-repeat: no-repeat;
       background-size: contain;
   }
   .left-banner-bg
    {
        background-image: none;
    }

   .banner-left-side
   {
       max-width: 400px;
       margin: 0 auto;
       margin-top: 70px;
       text-align: left;
   }

   .banner-content-heading
   {
       font-size: 1.9rem;

   }

   .text-goat
   {
       font-size: 2.6rem;
   }
   .btn-banner
    {
        padding: 10px 25px;
        grid-gap: 12px;
        gap: 12px;
        font-size: 01rem;
        margin-right: 12px;
    }

   .banner-right-side
   {
       display: flex;
       justify-items: right;
       width: 93%;
       margin: 0 auto;
       margin-bottom: 50px;
   }
   .banner-right-side > img
   {
       width: 100%;
       object-fit: cover;
   }


    /* online games funny video section */
    .online-game-funny-video-section
    {
        text-align: center;
    }
    .online-game-text
    {
        font-size: 2.6rem;
    }
    .grid-box-layout
    {
        grid-template-columns: repeat(2, 1fr);
    }


      /* Play Instant games section */
   .play-instant-game-section
   {
       display: flex;
       margin-top: 0px;
       flex-direction: row-reverse;

   }
   .play-instant-text
   {
       width: 200px;
       margin-left: 120px;
       margin-top: 100px;
       margin-right: 100px;
   }
   .play-instant-text h3
   {
       font-size: 2rem;
   }
   .play-instant-text p
   {
    font-size: 1rem;
   }
   .play-instant-image-container
   {
       width: 50%;
       margin-top: 100px;
   }
   .cow-boy-image {
       width: 70%;
   }
   .small-image
   {
       width: 40%;
       margin-top: -100px;
       margin-left: 100px;
   }

   /* footer section styles */
   footer
   {
       width: calc(100% - 160px);
       padding-top: 50px;
       padding: 50px 80px;
   }
   .footer-content-container
   {
       width: 100%;
       margin: 0 auto;
       margin-bottom: 10px;
       text-align: center;
   }
   .footer-logo-container
   {
       display: flex;
       justify-content: center;
       width: calc(100% - 20%);
   }

   .footer-logo-container img
   {
        width: 60px;
   }
   .footer-logo-text
   {
       color: #FFF;
       font-size: 35px;
       font-weight: 700;
       margin-left: 15px;
   }
   .footer-p-tag-container
   {
        display: flex;
        width: 90%;
        display: flex;
        padding: 10px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-wrap: wrap;
        width: 500px;
   }

   .footer-services-text
   {
       color: #FFF;
       width: 60px;
       text-align: center;
       font-size: 10px;
       font-weight: 600px;
       margin-right: 5px;
   }
   .footer-icon-container
   {
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .footer-icon-container i
   {
       color: #FFF;
       padding-left: 50px;
       width: calc(100% - 20%);
   }
   .terms-condition
   {
       margin-top: 20px;
       width: 100%;
       flex-wrap: wrap;
   }
   .footer-text
   {
       color: #ffffff99;
       font-size: 10px;
       font-weight: 500;
   }
   .policy-text
   {
    width: 120px;
   }
   .last-part
   {
       margin-bottom: 20px;
   }


}

#mobileControls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Ajustez la largeur selon vos besoins */
  text-align: center;
}

#mobileControls button {
  font-size: 24px; /* Ajustez la taille de la police selon vos besoins */
  width: 60px; /* Ajustez la largeur des boutons selon vos besoins */
  height: 60px; /* Ajustez la hauteur des boutons selon vos besoins */
  border-radius: 50%;
  background-color: #fff; /* Couleur de fond */
  border: 2px solid #000; /* Bordure */
  cursor: pointer;
  margin: 5px;
}

#upArrow {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}

#downArrow {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

#leftArrow {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

#rightArrow {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

#mobileControls button:focus {
  outline: none; /* Supprime la bordure focus par défaut */
}

body {
  background: black;
}

#canvas1 {
  border:  1px solid white;
  position: absolute;
  top:  50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 600px;
  background: white;
}

canvas {
  max-width: 85%; /* Pour que le canvas soit toujours à 100% de la largeur de l'écran */
  height: auto; /* Pour conserver le ratio de l'image */
  display: block; /* Pour éviter les espaces supplémentaires */
  border: 2px solid black; /* Bordure */
  background-color: #ccc; /* Couleur de fond */
  margin: 20px auto; /* Marge externe */
}

* {
  padding: 0;
  margin: 0;
}

canvas {
  background: #eee;
  display: block;
  margin: 0 auto;
}

