#mobileControls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Ajustez la largeur selon vos besoins */
  text-align: center;
}

#mobileControls button {
  font-size: 24px; /* Ajustez la taille de la police selon vos besoins */
  width: 60px; /* Ajustez la largeur des boutons selon vos besoins */
  height: 60px; /* Ajustez la hauteur des boutons selon vos besoins */
  border-radius: 50%;
  background-color: #fff; /* Couleur de fond */
  border: 2px solid #000; /* Bordure */
  cursor: pointer;
  margin: 5px;
}

#upArrow {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}

#downArrow {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

#leftArrow {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

#rightArrow {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

#mobileControls button:focus {
  outline: none; /* Supprime la bordure focus par défaut */
}
