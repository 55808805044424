body {
  background: black;
}

#canvas1 {
  border:  1px solid white;
  position: absolute;
  top:  50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 600px;
  background: white;
}
